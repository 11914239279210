import { makeAutoObservable } from 'mobx';
import { streamScreen } from '../types/enums';

class Stream {
  constructor() {
    makeAutoObservable(this);
  }

  private _ready: boolean = false;
  private _screen: streamScreen = null;
  private _timer: number = null;
  private _question: IquestionStream;
  private _disable: boolean = false;
  private _answer: number = null;
  private _correct: boolean = null;
  private _ratings: IratingStream[] = [];
  private _place: number = null;
  private _score: number = null;
  private _prize: boolean = false;
  private _tutorial: boolean = false;
  
  public setScreen(screen: streamScreen): void {
    this._screen = screen;
  }

  public getScreen(): streamScreen {
    return this._screen;
  }

  public setTimer(timer: number): void {
    this._timer = timer;
  }

  public getTimer(): number {
    return this._timer;
  }

  public setReady(ready: boolean): void {
    this._ready = ready;
  }

  public isReady(): boolean {
    return this._ready;
  }

  public setQuestion(question: IquestionStream): void {
    this._question = question;
  }

  public getQuestion(): IquestionStream {
    return this._question;
  }

  public setDisable(disable: boolean): void {
    this._disable = disable;
  }

  public isDisable(): boolean {
    return this._disable;
  }

  public setAnswer(answer: number): void {
    this._answer = answer;
  }

  public getAnswer(): number {
    return this._answer;
  }

  public setCorrect(correct: boolean): void {
    this._correct = correct;
  }

  public isCorrect(): boolean {
    return this._correct;
  }

  public setRatings(ratings: IratingStream[]): void {
    this._ratings = ratings;
  }

  public getRatings(): IratingStream[] {
    return this._ratings;
  }

  public setPlace(place: number): void {
    this._place = place;
  }

  public getPlace(): string | number {
    return this._place ?? '-';
  }

  public setScore(score: number): void {
    this._score = score;
  }

  public getScore(): string | number {
    return this._score ?? '-';
  }

  public setPrize(prize: boolean): void {
    this._prize = prize;
  }
  
  public isPrize(): boolean {
    return this._prize;
  }

  public setTutorial(tutorial: boolean): void {
    this._tutorial = tutorial;
  }
  
  public isTutorial(): boolean {
    return this._tutorial;
  }
}

export default new Stream();