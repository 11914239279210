import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { screen } from '../types/enums';
import State from '../data/State';
import Session from '../data/Session';
import User from '../data/User';
import { sounds } from '../data/assets';

class Handler {

  private static _music: HTMLAudioElement;

  public static getQuestion(): void {
    axios.post(process.env.REACT_APP_API + '/getSuperQuestion', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        const question = JSON.stringify(res.data.data);
        Session.setQuestion(question);
        const src  = sounds.find(data => data.name === 'round3').src;
        this._music = new Audio(src);
        this._music.play();
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(e => {});
  }

  public static sendAnswer(answer: string): void {
    if (Session.isDisabled()) return;
    this._music.pause();
    Session.setDisable(true);
    answer = answer.trim();
    answer = answer.toLocaleLowerCase();

    axios.post(process.env.REACT_APP_API + '/sendSuperQuestion', {
      id: User.getUser().id,
      session: Session.getID(),
      answer: answer,
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        Session.setDisable(false);
        Session.setSuperQuestion(res.data.data);
        User.setSuperQuestion(State.getSuperQuestion().id);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(err => window.location.reload());
  }
}

export default observer(() => {
  const [answer, setAnswer] = useState('');
  useEffect(() => {
    Handler.getQuestion();
  }, []);

  const answerClassName = Session.isDisabled() || answer === '' ? 'button-grey' : 'button-blue';

  return (
    <div>
      <div className='header'>СУПЕРВОПРОС</div>
      { Session.getSuperQuestion() !== null ?
      <>
        <div className='super-question-content'>
          <div className='super-question-descr'>Результат ответа</div>
          { Session.getSuperQuestion() ?
          <div className='complete-score'>ВЕРНО! +25 БАЛЛОВ</div>
          :
          <div className='complete-fail'>НЕВЕРНО!</div>
          }
          <div className='super-question-info'>Лови следующий супервопрос ровно через неделю, { State.getQuestionCopyright1() } во время выпуска «Умнее всех» на Пятнице!<br />Итоги розыгрыша будут { State.getQuestionCopyright2() }.</div>
        </div>
        <div className='footer'>
          <div className='button-red' onClick={() => {
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}>В МЕНЮ</div>
        </div>
      </>
      :
      <>
        <div className='super-question-content'>
          <div className='super-question-descr'>Включай телеканал<br />Пятница!</div>
          <div className='super-question-info'><span>Внимание!</span>Супервопрос появился прямо сейчас в эфире шоу «Умнее всех»! Отвечай на него и зарабатывай дополнительные баллы!</div>
          <input type='text' className='input' onChange={e => setAnswer(e.target.value)} />
        </div>
        <div className='footer' style={{ marginTop: 10 }}>
          <div className={ answerClassName } onClick={() => {
            if (answer !== '') {
              Handler.sendAnswer(answer);
              State.playSound('button');
            }
          }}>ДАЛЕЕ</div>
        </div>
      </>
      }
    </div>
  );
});