class Utils {

  public static convertTime(time: number): string {
    const hours = Math.floor(time / (60 * 60));
    const minutes = Math.floor((time - (hours * 60 * 60)) / 60);
    const seconds = time - (hours * 60 * 60) - minutes * 60;
    return hours + ':' + (minutes.toString().length === 1 ? '0' + minutes : minutes) + ':' + (seconds.toString().length === 1 ? '0' + seconds : seconds);
  }

  public static timer(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time - (minutes * 60);
    return (minutes.toString().length === 1 ? '0' + minutes : minutes) + ':' + (seconds.toString().length === 1 ? '0' + seconds : seconds);
  }

  public static link(link: string): void {
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.href = link;
    a.click();
    document.body.removeChild(a);
  }

  public static random(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min);
  }
  
  public static declension(score: number, words: string[] = ['балл', 'балла', 'баллов']): string {
    const lastDigit = score % 10;
    let word: string;

    if (lastDigit === 1) word = words[0];
    else if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) word = words[1];
    else word = words[2];

    if (score > 10) {
      const lastDigits = score % 100;
      if (lastDigits === 11 || lastDigits === 12 || lastDigits === 13 || lastDigits === 14) word = words[2];
    }
    return word;
  }
}

export default Utils;