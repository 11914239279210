import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import State from '../data/State';
import IOSTopIndent from '../components/IOSTopIndent';

export default observer(() => {
  return (
    <div className='page'>
      <div className='header'>
        <IOSTopIndent />
        ПРАВИЛА
      </div>
      <div className='rules-content'>
        <div className='rules-message'>
          <div>
            <div>Тина Канделаки проведёт викторину со зрителями прямо в эфире Пятницы! сразу после выпуска «Умнее всех». В игре будет два раунда — отвечай на вопросы в приложении и  получи шанс разделить призовой фонд в 50 000 рублей. Итоги викторины мы подведём в реальном времени в эфире Пятницы!</div>
            <div>«Умнее всех» — с 3 октября каждый четверг в 22:00 на Пятнице!</div>
            <br />
            <div>Тренировочный режим состоит из трёх раундов и запускается по кнопке «Играть».</div>
            <div>- Первый раунд состоит из десяти вопросов.</div>
            <div>- Второй раунд — вопросы по категориям, которые будут обновляться каждую неделю.</div>
            <div>- Финальный раунд — один вопрос с открытым ответом.</div>
            <div>В рейтинге игроков учитываются ответы на вопросы всех раундов. Ты можешь сыграть только один раз в день. Заходи в приложение, тренируйся и увеличивай шансы на победу в «Суперигре со зрителями» с Тиной Канделаки!</div>
            <br />
            <div>Подробные правила и условия: <a target='_blank' href="https://spec.friday.ru/docs/umneestream/rules.pdf">spec.friday.ru/docs/umneestream/rules.pdf</a></div> 
          </div>
        </div>
      </div>

      <div className='footer'>
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.MAIN);
            State.playSound('button');
          }}
        >В МЕНЮ</div>
      </div>
    </div>
  );
});