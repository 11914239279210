enum screen {
  LOADING,
  ERROR,
  MAIN,
  RULES,
  READY,
  ROUND_1,
  ROUND_2,
  ROUND_3,
  RESULT,
  RATINGS,
  SUPER_QUESTION,
  STREAM,
  PRIZE
}
enum error {
  AUTH = 1,
  DATA = 2,
  ATTEMPTS = 3,
  SESSION = 4,
  QUESTIONS = 5,
  HASH = 6,
  CATEGORY = 7
}
enum video {
  START_MIMUTE,
  ANSWER_RIGHT,
  ANSWER_WRONG,
  LAST_ROUND_LATE,
  LAST_ROUND_RIGHT,
  LAST_ROUND_WRONG,
  RESULT_FRIDAY,
  RESULT_NOT_FRIDAY
}
enum streamScreen {
  PRE_STREAM = 1,
  FIRST_ROUND = 2,
  FIRST_DONE = 3,
  FIRST_RATINGS = 4,
  PRE_FINAL = 5,
  FINAL_ROUND = 6,
  FINAL_DONE = 7,
  FINAL_RATINGS = 8
}

export {
  screen,
  error,
  video,
  streamScreen
}