import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { screen, video } from '../types/enums';
import bridge from '@vkontakte/vk-bridge';
import State from '../data/State';
import Session from '../data/Session';
import Utils from '../data/Utils';
import axios from 'axios';
import User from '../data/User';

export default observer(() => {
  const [place, setPlace] = useState<number>(null);
  useEffect(() => {
    const friday = new Date().getDay() === 5;
    Session.setVideo(friday ? video.RESULT_FRIDAY : video.RESULT_NOT_FRIDAY);
    axios.post(process.env.REACT_APP_API + '/getRating', {
      id: User.getUser().id
    }).then(res => {
      const rating = res.data.data;
      const user = rating.find((data: Irating) => data.self);
      setPlace(user.place);
    }).catch(e => {});
  }, []);

  const word = Utils.declension(Session.getScore()).toUpperCase();

  return (
    <div className='page'>
      <div className='bottom-round-gradient'></div>
      <div className='result-image'>

      </div>
      <div className='result-content'>
        <div className='result-message'>
          <div className='result-score'>{ Session.getScore() } { word }</div>
          { place && <div>Место в рейтинге: { place }</div> }
          {/* <div>Результаты будут известны<br />{ State.getResultCopyright() }</div> */}
        </div>
      </div>
      <div className='footer'>
        <div className={ Session.isDisabled() ? 'button-grey' : 'button-red'} onClick={async () => {
          if (Session.isDisabled()) return;
          Session.setDisable(true);
          const record = Session.getScore();
          const word = Utils.declension(Session.getScore());

          const token = await bridge.send("VKWebAppGetAuthToken", { app_id: Number(process.env.REACT_APP_APP_ID), scope: 'photos' });
          const url = await bridge.send("VKWebAppCallAPIMethod", { method: 'photos.getWallUploadServer', params: {
            access_token: token.access_token,
            v: '5.131',
            user_id: User.getUser().id
          }});
          const data = await axios.post(process.env.REACT_APP_API + '/createShareImage', {
            id: User.getUser().id,
            record: record,
            url: url.response.upload_url
          }).then(res => res.data);
          const { server, photo, hash } = data;
          const result = await bridge.send("VKWebAppCallAPIMethod", { method: 'photos.saveWallPhoto', params: {
            access_token: token.access_token,
            v: '5.131',
            photo: photo,
            server: server,
            hash: hash,
            user_id: User.getUser().id
          }});
          const image = result.response[0];
          const { owner_id, id } = image;
          const attachment = `photo${owner_id}_${id}`;
          const postBox = await bridge.send('VKWebAppShowWallPostBox', {
            message: 'У меня новый рекорд в "Умнее всех" - ' + record + ' ' + word + '! Сможешь побить его и обогнать меня в битве за 50 000 рублей? https://vk.com/app' + process.env.REACT_APP_APP_ID,
            attachments: attachment
          }).then(data => {
            Session.setDisable(false);
          }).catch(err => {
            Session.setDisable(false);
          });
        }}>ПОДЕЛИТЬСЯ</div>
        <div className='button-red' onClick={() => {
          State.setScreen(screen.MAIN);
          State.playSound('button');
        }}>В МЕНЮ</div>
      </div>
      <div className='decor-1'></div>
      <div className='decor-2'></div>
      <div className='decor-3'></div>
      <div className='decor-4'></div>
    </div>
  );
});