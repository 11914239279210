import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screen, video } from '../types/enums';
import Session from '../data/Session';
import State from '../data/State';
import User from '../data/User';
import Ready from '../components/Ready';
import Complete from '../components/Complete';

class Handler {

  private static _interval: NodeJS.Timer;

  public static ready(): void {
    Session.setReady(1);
    this._getUsersCategories();
  }

  public static startTimer(): void {
    State.playTimer();
    this._interval = setInterval(() => {
      if (Session.getTimer() > 1) {
        Session.setTimer(Session.getTimer() - 1);
      } else {
        this.stopTimer();
        Session.setQuestion(null);
        Session.setDisable(true);
        Session.setTimer(null);
        Session.setVideo(null);
        Session.setComplete(true);
      }
    }, 1000);
  }

  public static stopTimer(): void {
    State.pauseTimer();
    clearInterval(this._interval);
  }

  private static _getUsersCategories(): void {
    axios.post(process.env.REACT_APP_API + '/getUsersCategories', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      search: User.getSearch()
    }).then(res => {
      const categories: Icategory[] = [];
      res.data.data.categories.map((category: Icategory) => {
        categories.push({
          id: category.id,
          name: category.name,
          disable: false
        });
      });
      res.data.data.disable.map((id: number) => {
        const category = categories.find(category => category.id === id);
        category.disable = true;
      });
      Session.setCategories(JSON.stringify(categories));
    }).catch(e => {});
  }

  public static chooseCategory(id: number): void {
    if (Session.isDisabled()) return;
    Session.setRight(id);
    Session.setDisable(true);

    axios.post(process.env.REACT_APP_API + '/chooseCategory', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      category: id,
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        Session.setQuestions(res.data.data);
        Session.setCategories(null);
        Session.setRight(null);
        Session.setVideo(null);
        Session.setTimer(60);
        this.setQuestion();
        this.startTimer();
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(e => {});
  }

  public static setQuestion(): void {
    if (!Session.isDisabled()) return;
    const index = Session.getCountQuestion() - 1;
    const question = JSON.stringify(Session.getQuestions()[index]);
    Session.setQuestion(question);
    Session.setDisable(false);
  }

  public static sendAnswer(id: number, answer: number): void {
    if (Session.isDisabled()) return;
    Session.setDisable(true);
    this.stopTimer();
    axios.post(process.env.REACT_APP_API + '/sendQuestionSecondRound', {
      id: User.getUser().id,
      session: Session.getID(),
      hash: Session.getHash(),
      question: id,
      answer: answer,
      search: User.getSearch()
    }).then(res => {
      if (!res.data.error) {
        Session.setScore(Session.getScore() + res.data.data.score);
        this._flashButtons(res.data.data.right, answer);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(e => {});
  }

  private static _flashButtons(right: number, answer: number): void {
    Session.setRight(right);
    Session.setAnswer(answer);
    const result = right === answer;
    result ? State.playSound('rightAnswer') : State.playSound('wrongAnswer');
    setTimeout(() => {
      this._setNext();
    }, 1000);
  }

  private static _setNext(): void {
    if (Session.getCountQuestion() === Session.getQuestions().length) {
      Session.setQuestion(null);
      Session.setComplete(true);
    } else {
      Session.plusQuestion();
      this.setQuestion();
      this.startTimer();
    }
  }
}

export default observer(() => {
  useEffect(() => {
    Handler.ready();
  }, []);
  const [message, setMessage] = useState(false);

  const showMessage = () => {
    if (message === true) return; 
    setMessage(true);
    setTimeout(() => {
      setMessage(false);
    }, 4000);
  }

  const data = Session.getQuestion() && JSON.parse(Session.getQuestion()) as Iquestion;
  const answers = data && data.answers.map(answer => {
    const className = answer.id === Session.getAnswer() && Session.getAnswer() !== Session.getRight() ? 'btn-red' :
      answer.id === Session.getRight() ? 'btn-green' :
      'btn-blue';
    return (
      <div
        key={ answer.id }
        className={ 'btn ' + className }
        onClick={() => {
          Handler.sendAnswer(data.question.id, answer.id);
        }}
      >{ answer.text }</div>
    );
  });

  const quiz = Session.getReady() === null;

  const choose = Session.getCategories() && Session.getReady() === null && JSON.parse(Session.getCategories()) as Icategory[];
  const categories = choose && choose.map(category => {
    const className = category.disable ? 'btn-grey' : category.id === Session.getRight() ? 'btn-green' : 'btn-blue'
    return (
      <div
        key={ category.id }
        className={ 'btn ' + className }
        onClick={() => {
          if (!category.disable) {
            setMessage(false);
            Handler.chooseCategory(category.id);
          } else {
            showMessage();
          }
        }}
      >{ category.name }</div>
    );
  });
  
  return (
    <div className='page'>
      { quiz && <div className='bottom-round-gradient'></div> }
      { quiz && <div className='round-image'></div> }
      { quiz && data && <div className='round-header'>ВОПРОС { Session.getCountQuestion() }</div> }
      { quiz && data && <div className='round-question-block'>
        <div className='question-header'>{ data.question.text }</div>
        <div className='answers-block'>{ answers }</div>
      </div> }
      { quiz && !choose && <div className='round-timer'>{ Session.getTimer() ? Session.getTimer() : 0 }</div> }
      { quiz && <div className='decor-1'></div> }
      { quiz && <div className='decor-5'></div> }

      { choose && <div className='choose-block'>
        <div className='round-header'>Выберите категорию</div>
        <div className='round-categories-block'>
          <div className='answers-block'>{ categories }</div>
        </div>
      </div> }

      { !quiz && <Ready round={ 2 } /> }
      { Session.getComplete() && <Complete round={ 2 } /> }
      { message && <div className='message'>Эту категорию ты уже<br />выбирал на этой неделе!<br />Сегодня тебе нужно сыграть<br />в другую категорию.</div> }
    </div>
  );
});