import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { error, screen } from '../types/enums';
import axios from 'axios';
import vkBridge from '@vkontakte/vk-bridge';
import vkBridgeMock from '@vkontakte/vk-bridge-mock';
import State from '../data/State';
import Session from '../data/Session';
import Sockets from '../data/Sockets';
import User from '../data/User';
import {
  images,
  video,
  sounds
} from '../data/assets';

const bridge = process.env.REACT_APP_DEV === 'true' ? vkBridgeMock : vkBridge;

class Loader {
  constructor() {
    this._init();
  }

  private _data: boolean;
  private _images: boolean;
  private _video: boolean;
  private _sounds: boolean;
  private _imageCounter: number;
  private _videoCounter: number;
  private _soundsCounter: number;

  private _init(): void {
    this._data = false;
    this._images = false;
    this._video = false;
    this._sounds = false;
    this._imageCounter = 0;
    this._videoCounter = 0;
    this._soundsCounter = 0;
    this._getData();

    // if (new Date().getDay() === 4) {
    //   this._images = true;
    //   this._video = true;
    //   this._sounds = true;
    //   this._done();
    // } else {
      this._loadImages();
      this._loadVideos();
      this._loadSounds();
    // }
  }

  private async _getData(): Promise<void> {
    await bridge.send('VKWebAppInit');
    bridge.send('VKWebAppGetUserInfo').then(data => {
      if (data.id) {
        User.setUser(data);
        User.setSearch(window.location.search);

        bridge.send('VKWebAppGetLaunchParams', {}).then(res => {
          State.setNotifications(Boolean(res.vk_are_notifications_enabled));
        }).catch(e => {});

        axios.post(process.env.REACT_APP_API + '/getData', {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          search: User.getSearch()
        }).then(res => {
          if (res.data.error) {
            State.setError(error.DATA);
            State.setScreen(screen.ERROR);
          } else {
            User.setBan(res.data.data.ban);
            // User.setSuperQuestion(res.data.data.superQuestion);

            // if (res.data.data.question) {
            //   State.setSuperQuestion(res.data.data.question);
            // }
            // User.setOld(res.data.data.old);
            // State.setRatingCopyright(res.data.data.ratingsCopyright);
            // State.setResultCopyright(res.data.data.resultCopyright);
            // State.setQuestionCopyright1(res.data.data.questionCopyright1);
            // State.setQuestionCopyright2(res.data.data.questionCopyright2);
            // State.setQuestionTime(res.data.data.questionTime);
            this._data = true;
            this._done();
            // new Sockets();
          }
        }).catch(e => {
          this._data = true;
          this._done();
          // new Sockets();
        });
      } else {
        State.setError(error.AUTH);
        State.setScreen(screen.ERROR);
      }
    }).catch(err => {
      State.setError(error.AUTH);
      State.setScreen(screen.ERROR);
    });
  }

  private _loadImages(): void {
    images.map(url => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this._imageCounter++;
        this._setLoadingProgress();

        if (this._imageCounter === images.length) {
          this._images = true;
          this._done();
        }
      }
    });
  }

  private _loadVideos(): void {
    video.map(url => {
      const v = document.createElement('video');
      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      req.responseType = 'blob';
      req.onload = () => {
        if (req.status === 200) {
          const videoBlob = req.response;
          const vid = URL.createObjectURL(videoBlob);
          v.src = vid;
          v.remove();
          this._videoCounter++;
          this._setLoadingProgress();

          if (this._videoCounter === video.length) {
            this._video = true;
            this._done();
          }
        }
      }
      req.send();
    });

    if (video.length === 0) {
      this._video = true;
      this._done();
    }
  }

  private _loadSounds(): void {
    sounds.map(sound => {
      State.sounds[sound.name] = new Audio();
      State.sounds[sound.name].src = sound.src;
      this._soundsCounter++;
      this._setLoadingProgress();

      if (this._soundsCounter === sounds.length) {
        this._sounds = true;
        this._done();
      }
    });

    if (sounds.length === 0) {
      this._sounds = true;
      this._done();
    }
  }

  private _setLoadingProgress(): void {
    const weights = {
      images: 20,
      video: 20,
      sounds: 20,
      user: 20,
      data: 20
    }
    const i = images.length === 0 ? weights.images : weights.images / images.length * this._imageCounter;
    const v = video.length === 0 ? weights.video : weights.video / video.length * this._videoCounter;
    const s = sounds.length === 0 ? weights.sounds : weights.sounds / sounds.length * this._soundsCounter;
    const b = User.getUser() ? weights.user : 0;
    const d = this._data ? weights.data : 0;
    State.setLoading(Math.floor(i + v + s + b + d));
  }

  private _done(): void {
    const data = {
      data: this._data,
      video: this._video,
      images: this._images,
      sounds: this._sounds
    }
    
    if ([2314852, 191781124, 456849156].find(data => data === User.getUser()?.id)) {
      Session.setHash(JSON.stringify(data));
    }

    if (this._data && this._video && this._images && this._sounds) {
      State.setLoaded(true);
    }
  }
}

export default observer(() => {
  useEffect(() => {
    new Loader();
  }, []);
  const data = Session.getHash();
  return (
    <div className='page' style={{ justifyContent: 'space-around' }}>
      { data && 
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1000,
          width: '50vw',
          textShadow: '1px 1px 1px #000'
        }}>
        <div style={{ color: 'yellow' }}>Данные - { JSON.parse(data).data ? 'загружены' : 'ожидается' }</div>
        <div style={{ color: 'yellow' }}>Видео - { JSON.parse(data).video ? 'загружено' : 'ожидается' }</div>
        <div style={{ color: 'yellow' }}>Картинки - { JSON.parse(data).images ? 'загружены' : 'ожидается' }</div>
        <div style={{ color: 'yellow' }}>Звуки - { JSON.parse(data).sounds ? 'загружены' : 'ожидается' }</div>
        <div style={{ color: 'yellow' }}>Версия - 2.5</div>
      </div> }
      <div className='loading'></div>
      <div className='friday'></div>

      <div className='footer fixed-footer'>
        { State.getLoaded() ? 
        <div
          className='button-red'
          onClick={() => {
            State.setScreen(screen.MAIN);
            Session.setHash(null);
            State.playSound('button');
          }}
        >ДАЛЕЕ</div>
        :
        <div className='loading-bar'>{ State.getLoading() }%</div>
        }
      </div>
    </div>
  );
});